:root {
  --title-letter-spacing: auto;

  --title-24-line-height: 45px;
  --title-30-line-height: 45px;
  --title-32-line-height: 45px;
  --title-44-line-height: 45px;

  --title-scale-0-1: 0.1rem;

  --title-font-24: calc(var(--base-title-font-size) * 2.4);
  --title-font-30: calc(var(--base-title-font-size) * 3);
  --title-font-32: calc(var(--base-title-font-size) * 3.2);
  --title-font-44: calc(var(--base-title-font-size) * 4.4);
}

.title-24,
.title-30, 
.title-32, /* common */
.title-44 {
  font-family: var(--title-font-family);
  letter-spacing: var(--title-letter-spacing);
}

/* ---------- title 24 ---------- */
.title-24 {
  font-size: var(--title-font-24);
}
@media (min-width: 1600px) {
  .title-24 {
    font-size: calc(var(--title-font-24) + var(--title-scale-0-1));
  }
}
@media (max-width: 380px) {
  .title-24 {
    font-size: calc(var(--title-font-24) - var(--title-scale-0-1));
  }
}

/* ---------- title 30 ---------- */
.title-30 {
  font-size: var(--title-font-30);
}
@media (min-width: 1600px) {
  .title-30 {
    font-size: calc(var(--title-font-30) + var(--title-scale-0-1));
  }
}
@media (max-width: 380px) {
  .title-30 {
    font-size: calc(var(--title-font-30) - var(--title-scale-0-1));
  }
}

/* ---------- title 32 ---------- */
.title-32 {
  font-size: var(--title-font-32);
}
@media (min-width: 1600px) {
  .title-32 {
    font-size: calc(var(--title-font-32) + var(--title-scale-0-1));
  }
}
@media (max-width: 380px) {
  .title-32 {
    font-size: calc(var(--title-font-32) - var(--title-scale-0-1));
  }
}

/* ---------- title 44 ---------- */
.title-44 {
  font-size: var(--title-font-44);
}
@media (min-width: 1600px) {
  .title-44 {
    font-size: calc(var(--title-font-44) + var(--title-scale-0-1));
  }
}
@media (max-width: 380px) {
  .title-44 {
    font-size: calc(var(--title-font-44) - var(--title-scale-0-1));
  }
}
