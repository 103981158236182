.antForm {
  width: 100%;
}

/* ---------- antd blue focus ---------- */
.antForm :global(.ant-input-outlined:focus) {
  box-shadow: none !important;
}

/*------------ input -----------*/
.antForm :global(.ant-form-item) {
  margin-bottom: 30px;
}
.antForm :global(.ant-form-item input) {
  height: 45px;
  border-radius: 3px;
  background-color: #3636360d;
  color: #383838;
  font-weight: 400;
  letter-spacing: 0.5px;
  border-color: transparent;
}
.antForm :global(.ant-input:hover),
.antForm :global(.ant-input:focus) {
  border-color: #000000 !important;
  transition: all 0.5s;
}
.antForm :global(.ant-form-item input::placeholder) {
  color: #777777;
}

/*----------- text area ----------*/
.antForm :global(.ant-form-item textarea) {
  width: 100%;
  height: 65px;
  border-radius: 3px;
  height: 75px;
  border-radius: 3px;
  background-color: #3636360d !important;
  color: #383838;
  border-color: transparent !important;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.antForm :global(.ant-form-item textarea::placeholder) {
  color: #777777;
}

/*------------ input error ---------*/
.antForm :global(.ant-form-item .ant-form-item-explain-error) {
  font-size: 12px;
  margin-top: 2px;
  color: #ee0606;
}
.antForm :global(.ant-form-item .ant-input-status-error),
.antForm :global(.ant-form-item .ant-input-status-error:hover),
.antForm :global(.ant-form-item .ant-input-status-error:focus) {
  border-color: #ee0606 !important;
}

/* ----------------media quries----------------
   -------------------------------------------- */
@media (max-width: 425px) {
  .antForm :global(.ant-form-item button) {
    width: 130px;
    height: 40px;
    font-size: 17px;
  }
}
@media (max-width: 768px) {
}
