.skeleton {
  width: 100%;
  height: 100%;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    /* gray color */ rgba(130, 130, 130, 0.2) 8%,
    /* white color */ rgba(130, 130, 130, 0.3) 18%,
    /* gray color */ rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-squares 2s infinite ease-out;
}

@keyframes wave-squares {
  0% {
    background-position: -368px 0;
  }
  100% {
    background-position: 368px 0;
  }
}
