.studio-theme {
  /* ---------- theme font ---------- */
  --title-font-family: "poppins";
  --text-font-family: "poppins";
  --default-font-family: "poppins";

  /* ---------- theme colors ---------- */
  --primary-color: #136dff;
  --primary-light-color: #3783ff;
  --primary-dark-color: #0b59d6;
}
