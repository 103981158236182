.mobileNavbar {
  display: none;
}

@media (max-width: 670px) {
  .mobileNavbar {
    display: block;
  }
  .webNavbar {
    display: none;
  }
}
