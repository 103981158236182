/* Ripple effect */
.rippleEffect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: transparent;
}
.rippleCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.25);
}
.rippleEffect:global(.isActive) .rippleCircle {
  animation: ripple-animation 0.4s ease-in;
}
.loaderContainer {
  margin-right: 7px;
}

/* Ripple animation */
@keyframes ripple-animation {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    width: 200%;
    padding-bottom: 200%;
    opacity: 0;
  }
}
