/* ---------- antform override ---------- */
.loginForm :global(.ant-form-item) {
  margin-bottom: 15px !important;
}
.loginForm :global(.ant-form-item input) {
  height: 45px;
  border-radius: 7px !important;
  background-color: transparent !important;
  border-color: var(--black-light-color) !important;
}
.loginSection {
  overflow: hidden;
}
.loginWrapper {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.loginContainer {
  padding: 30px 100px;
  box-shadow: var(--box-shadow-color);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
}
.logo {
  width: 150px;
  object-fit: contain;
  cursor: pointer;
  aspect-ratio: 1;
}
.title {
  margin: 20px 0 40px 0;
}
.description {
  color: var(--black-light-color);
  margin-top: 15px;
}
.description a {
  color: var(--black-color);
  cursor: pointer;
  margin-left: 7px;
}

.submitButton:global(.button) {
  width: 100%;
  background-color: var(--black-color);
  color: var(--white-color);
  padding: 8px 0;
  margin-top: 14px;
}
.submitButton:global(.button:hover) {
  background-color: var(--black-color);
}

/* ------------------------ media queries ------------------------ */
/* --------------------------------------------------------------- */
@media (max-width: 550px) {
  .loginContainer {
    padding: 30px;
    width: calc(90vw - 60px);
  }
}
