.logoContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}
.logoContainer img {
  width: 30px;
}
.headerWrapper {
  border-bottom: 1px solid #e4e4e4;
}
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 90%;
  max-width: 90%;
}
.menuContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}
.menuItem {
  cursor: pointer;
  transition: 0.3s color;
}
.menuItem:hover {
  color: var(--primary-color);
}
.lasMenuItem {
  background-color: var(--primary-color);
  color: var(--white-color);
  border-radius: 50px;
  padding: 17px 30px;
  line-height: 0;
  width: fit-content;
  cursor: pointer;
}
